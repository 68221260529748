body {
  margin: 0;
  padding: 0;
}
* {
  font-family: sans-serif;
  box-sizing: border-box;
}
.flexing {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainSection {
  margin-top: 60px;
  width: 80%;
  margin: 0 auto;
}
.title {
  gap: 8px;
  margin: 0;
}
h1 {
  text-align: center;
}

.icons {
  gap: 5px;
  font-size: 18px;
}
.main {
  margin: 12px;
  font-size: 40px;
  font-weight: 500 !important;
}
.entering {
  font-size: 30px;
  font-weight: 600 !important;
  margin-bottom: 40px;
}
a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  transition: 0.2s;
}
a:hover {
  color: rgb(112, 103, 103);
}
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.wrapper img {
  max-width: 600px;
  margin-top: 10px;
}
footer {
  margin-top: 60px;
  margin-bottom: 20px;
}
